import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useState} from 'react';
import {
  MobileMenuAccordionWrapper,
  MobileMenuAccordionContainer,
  MobileMenuAccordionTitleWrapper,
  MobileMenuAccordionTitle,
  MobileMenuAccordionContent,
  MobileMenuAccordionItem,
} from './MobileMenuAccordion.styles';

interface MobileMenuAccordionProps {
  title: string;
  content: Array<{title?: string; path: string}>;
  activeCategory: boolean;
}

const MobileMenuAccordion: FC<MobileMenuAccordionProps> = ({
  title,
  content,
  activeCategory,
}) => {
  const [isOpen, setIsOpen] = useState(activeCategory);

  const router = useRouter();

  return (
    <MobileMenuAccordionWrapper>
      <MobileMenuAccordionContainer>
        <MobileMenuAccordionTitleWrapper>
          <MobileMenuAccordionTitle onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            {title}
          </MobileMenuAccordionTitle>
        </MobileMenuAccordionTitleWrapper>
        <MobileMenuAccordionContent isOpen={isOpen}>
          {content?.map((item, idx) => (
            <MobileMenuAccordionItem
              key={idx}
              activeCategoryMenuItem={router.asPath.endsWith(item.path)}>
              <Link href={item.path} legacyBehavior>
                {item.title}
              </Link>
            </MobileMenuAccordionItem>
          ))}
        </MobileMenuAccordionContent>
      </MobileMenuAccordionContainer>
    </MobileMenuAccordionWrapper>
  );
};

export default MobileMenuAccordion;
