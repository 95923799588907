import styled from 'styled-components';
import {theme} from '@styles/theme';

export const MobileMenuInputWrapper = styled.div`
  background-color: ${theme.colors.lightBG};
  padding: 12px 15px;
  display: flex;
  & input {
    border: none;
    outline: none;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    background-color: transparent;
    letter-spacing: 0.5px;
    width: 100%;
    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: ${theme.colors.gray};
    }
  }
`;

export const SearchIcon = styled.div`
  padding-right: 12px;
`;

export const CleanInputIcon = styled.div<{show: boolean}>`
  cursor: pointer;
  display: ${({show}) => (show ? '' : 'none')};
  &:hover {
    filter: invert(75%);
  }
`;
