import {fontStyles, theme} from '@styles/theme';
import styled from 'styled-components';

export const MobileHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 999;
  background-color: white;
  min-height: 49px;
  max-height: 49px;
`;

export const BurgerWrapper = styled.div`
  @media (max-width: 698px) {
    padding-right: 38px;
  }
`;

export const LogoWrapper = styled.div`
  margin-right: auto;
  cursor: pointer;
  order: -1;
  font-size: 0;
  line-height: 0;
  margin-left: -2px;
  position: relative;
  width: 143px;
  height: 13px;
  @media (max-width: 698px) {
    order: 0;
  }
  @media (max-width: 420px) {
    width: 123px;
    height: 11px;
  }
`;

export const Phone = styled.div`
  a {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: ${theme.colors.darkText};
    margin-right: 20px;
    @media (max-width: 698px) {
      display: flex;
      height: 16px;
      span {
        display: none;
      }
    }

    img {
      @media (min-width: 699px) {
        display: none;
      }
    }
  }
  order: -1;
  @media (max-width: 698px) {
    order: 0;
  }
`;

export const CabHeaderBtns = styled.div`
  display: flex;
  align-items: center;
`;

export const UserLogo = styled.div`
  min-width: 16px;
  min-height: 16px;
  z-index: 999;
`;

export const UserText = styled.div`
  ${fontStyles.mainText2}
  white-space: nowrap;
  color: ${theme.colors.white};
  line-height: 16px;
`;

export const UserInfo = styled.div`
  @media (max-width: 680px) {
    display: none;
  }
`;

export const CabHeaderUserButton = styled.div`
  position: relative;
`;

export const CabHeaderUserButtonContents = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 8px;
  user-select: none;
`;
