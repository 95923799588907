import {theme} from '@styles/theme';
import styled from 'styled-components';

export const MobileMenuAccordionContainer = styled.div``;

export const MobileMenuAccordionWrapper = styled.div`
  padding: 0px 15px;

  ${MobileMenuAccordionContainer}:not(last-child) {
    border-bottom: 1px solid ${theme.colors.lightGrayV2};
  }
`;

export const MobileMenuAccordionTitleWrapper = styled.div``;

export const MobileMenuAccordionTitle = styled.div<{isOpen: boolean}>`
  display: flex;
  padding: ${({isOpen}) => (isOpen ? '15px 0 7px 0' : '15px 0')};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${({isOpen}) =>
    isOpen ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkText}`};
  cursor: pointer;
  &:after {
    content: url('/assets/icons/arrowDown.svg');
    display: block;
    margin-left: auto;
    transform: ${({isOpen}) =>
      isOpen ? 'rotateX(180deg) scale(1.5)' : 'rotateX(0deg)scale(1.5)'};
    ${({isOpen}) =>
      isOpen ? theme.filters.brandGreenV2 : `filter: ${theme.colors.darkText};`}
    transition: 0.45s all;
  }
`;

export const MobileMenuAccordionContent = styled.div<{isOpen: boolean}>`
  padding: 0 0 9px 10px;
  position: ${({isOpen}) => (isOpen ? 'static' : 'absolute')};
  font-size: 16px;
  height: fit-content;
  width: 100%;
  max-height: ${({isOpen}) => (isOpen ? '2050px' : '0')};
  opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
  transform: ${({isOpen}) => (isOpen ? 'scale(1)' : 'scale(0)')};
  transition: opacity 0.25s ease, max-height 0.25s ease-in;
`;

export const MobileMenuAccordionItem = styled.div<{activeCategoryMenuItem: boolean}>`
  a {
    display: block;
    padding: 7px 0;
    font-weight: ${({activeCategoryMenuItem}) =>
      activeCategoryMenuItem ? `500` : `400`};
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${({activeCategoryMenuItem}) =>
      activeCategoryMenuItem ? `${theme.colors.brandGreenV2}` : `${theme.colors.gray}`};
  }
`;
