import CabHeaderUserMenu from '@components/molecules/CabHeaderUserMenu';
import MobileHeaderMenu from '@components/molecules/MobileHeaderMenu';
import { PENDING } from '@constants/loadingState';
import { useAppDispatch } from '@hooks';
import { RootState } from '@store';
import { GlobalContainer } from '@styles/pages/_app.styles';
import { theme } from '@styles/theme';
import { getIsUseNewLKK } from '@utils/LKK_AB_test';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { HamburgerSqueeze } from 'react-animated-burgers';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import {
  BurgerWrapper,
  CabHeaderBtns,
  CabHeaderUserButton,
  CabHeaderUserButtonContents,
  LogoWrapper,
  MobileHeaderWrapper,
  Phone,
  UserInfo,
  UserLogo,
  UserText,
} from './MobileHeader.styles';

interface MobileHeaderProps {
  isAuth: boolean;
}

const MobileHeader: FC<MobileHeaderProps> = ({ isAuth }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
  const { account, accountLoading } = useSelector((state: RootState) => state.user);

  const userFullName = account?.last_name ? `${account?.last_name} ${account?.name}` : '';
  const isUseNewLKK = getIsUseNewLKK(account?.roles);

  const onCabinetClick = () => {
    if (accountLoading === PENDING) return;
    if (isAuth) {
      if (isUseNewLKK) {
        setUserMenuIsOpen(prev => !prev);
      } else {
        router.push('/cabinet');
      }
    } else {
      router.push('/cabinet/v2/auth');
    }
  }

  return (
    <GlobalContainer>
      <MobileHeaderWrapper>
        <BurgerWrapper>
          <HamburgerSqueeze
            isActive={isOpen}
            buttonStyle={{
              zIndex: 5,
              color: 'black',
              borderRadius: '20px',
              cursor: 'pointer',
              padding: '0',
            }}
            buttonWidth={15}
            toggleButton={() => setIsOpen(!isOpen)}
          />
          <MobileHeaderMenu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onCabinetClick={onCabinetClick}
          />
        </BurgerWrapper>
        <LogoWrapper>
          <Link href={'/'} passHref>
            <Image
              src={'/assets/icons/logo.svg'}
              layout="fill"
              objectFit="contain"
              alt="logo"
            />
          </Link>
        </LogoWrapper>
        <Phone>
          <Link href="tel:+78002005395" passHref>
            <span>+7 800 200-53-95</span>
            <Image
              src="/assets/mobileMenu/mobileCallingIcon.svg"
              layout="intrinsic"
              width={16}
              height={16}
              objectFit="contain"
              alt="Позвонить"
              title="Позвонить"
            />
          </Link>
        </Phone>
        <CabHeaderBtns>
          <CabHeaderUserButton
            onClick={onCabinetClick}>
            <CabHeaderUserButtonContents>
              <UserLogo>
                {accountLoading === PENDING ? (
                  <Skeleton
                    width={15}
                    baseColor={`${theme.colors.brandGreenV2Aplha5}`}
                    highlightColor={`${theme.colors.brandGreenV2BG}`}
                  />
                ) : (
                  <Image
                    src="/assets/icons/cabHeaderIcons/cabUserIcon.svg"
                    alt="ЛК иконка"
                    width={14}
                    height={14}
                  />
                )}
              </UserLogo>
              <UserInfo>
                {accountLoading === PENDING ? (
                  <>
                    <Skeleton
                      count={1}
                      width={100}
                      baseColor={`${theme.colors.brandGreenV2Aplha5}`}
                      highlightColor={`${theme.colors.brandGreenV2BG}`}
                    />
                  </>
                ) : (
                  <>
                    <UserText>{userFullName ? userFullName : 'Личный кабинет'}</UserText>
                    {/* <UserType>Клиент</UserType> */}
                  </>
                )}
              </UserInfo>
            </CabHeaderUserButtonContents>
            <CabHeaderUserMenu isOpen={userMenuIsOpen} setIsOpen={setUserMenuIsOpen} />
          </CabHeaderUserButton>
        </CabHeaderBtns>
      </MobileHeaderWrapper>
    </GlobalContainer>
  );
};

export default MobileHeader;
