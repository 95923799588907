import {theme} from '@styles/theme';
import styled from 'styled-components';

export const MobileHeaderMenuWrapper = styled.div`
  position: fixed;
  transform: ${({isOpen}: {isOpen: boolean}) =>
    isOpen ? 'translateX(0%)' : 'translateX(-150%)'};
  opacity: ${({isOpen}: {isOpen: boolean}) => (isOpen ? '1' : '0')};
  transition: transform 0.7s ease, opacity 0.3s;
  width: 88vw;
  height: 100vh;
  overflow: auto;
  background-color: white;
  z-index: 9999;
  top: 0;
  left: 0;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileMenuHeader = styled.div`
  background-color: white;
  display: flex;
  padding: 12px 0;
`;

export const CloseBtn = styled.div`
  width: 16px;
  height: 16px;
  padding-left: 15px;
  padding-right: 20px;
  cursor: pointer;
`;

export const MobileMenuHeaderTitle = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${theme.colors.darkText};
  margin-right: auto;
`;

export const CabIcon = styled.div`
  padding-right: 31px;
`;

export const CompareIcon = styled.div`
  padding-right: 15px;
`;

export const FavoriteIcon = styled.div`
  position: relative;
  padding-right: 15px;
`;

export const CartIcon = styled.div`
  position: relative;
  padding-right: 15px;
`;

export const MobileMenuSearchWrapper = styled.div``;

export const GeoIcon = styled.div`
  padding-right: 15px;
  display: flex;
  align-items: center;
`;

export const MobileMenuCategoriesWrapper = styled.div`
  padding-bottom: 100px;
`;

export const Quantity = styled.div`
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  top: -6px;
  right: 10px;
  color: ${theme.colors.brandGreenV2};
`;

export const MobileHeaderMenuBackdrop = styled.div<{isOpen: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({isOpen}) => (isOpen ? '999' : '-1')};
  background-color: ${theme.colors.darkText};
  opacity: ${({isOpen}) => (isOpen ? '0.3' : '0')};
  transition: ${({isOpen}) => (isOpen ? '1s opacity 0.25s' : 'none')};
  width: 12vw;
  height: ${({isOpen}) => (isOpen ? '100vh' : '0')};
`;
