import useDebounce from '@hooks/useDebounce';
import Image from 'next/legacy/image';
import {useRouter} from 'next/router';
import {useEffect, useRef, useState} from 'react';
import {
  MobileMenuInputWrapper,
  SearchIcon,
  CleanInputIcon,
} from './MobileMenuInput.styles';

const MobileMenuInput = () => {
  const [showCleanInput, setShowCleanInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef: any = useRef();

  const debouncedSearchQuery = useDebounce(searchQuery, 1000);

  const router = useRouter();

  const cleanInput = () => {
    if (inputRef.current.value.length !== 0) {
      setSearchQuery('');
      inputRef.current.focus();
      setShowCleanInput(false);
    }
    return;
  };

  const getSearch = (value: string) => {
    router.push({pathname: '/search', query: {query: value, page: 1}});
    setSearchQuery('');
  };

  useEffect(() => {
    if (debouncedSearchQuery.length === 0) {
      return;
    }
    getSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  return (
    <MobileMenuInputWrapper>
      <SearchIcon>
        <Image
          src="/assets/icons/searchIcon.svg"
          width={12}
          height={12}
          layout="intrinsic"
          objectFit="contain"
          alt="Поиск"
        />
      </SearchIcon>
      <input
        placeholder="Поиск"
        value={searchQuery}
        ref={inputRef}
        onChange={e => {
          if (e.target.value.length !== 0) {
            setShowCleanInput(true);
          } else {
            setShowCleanInput(false);
          }
          setSearchQuery(e.target.value);
        }}
      />
      <CleanInputIcon onClick={cleanInput} show={showCleanInput}>
        <Image
          src="/assets/mobileMenu/mobileMenuCleanInputIcon.svg"
          width={12}
          height={12}
          layout="intrinsic"
          objectFit="contain"
          alt="Очистить"
        />
      </CleanInputIcon>
    </MobileMenuInputWrapper>
  );
};

export default MobileMenuInput;
