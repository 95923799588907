import {Breadcrumb} from '@api/types/Breadcrumbs';
import MobileMenuInput from '@components/atoms/MobileMenuInput';
import {ENABLE_NEW_CABINET} from '@config/cabinet';
import {RootState} from '@store';
import {setConfirmModalActive} from '@store/slices/geolocation';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHeaderBreadcrumbsAnalytic } from '@hooks';
import {
  CabIcon,
  CartIcon,
  CloseBtn,
  CompareIcon,
  FavoriteIcon,
  GeoIcon,
  MobileHeaderMenuBackdrop,
  MobileHeaderMenuWrapper,
  MobileMenuCategoriesWrapper,
  MobileMenuHeader,
  MobileMenuHeaderTitle,
  MobileMenuSearchWrapper,
  Quantity,
} from './MobileHeaderMenu.styles';
import MobileMenuAccordion from './MobileMenuAccordion';

interface MobileHeaderMenuProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCabinetClick: () => void;
}

const MobileHeaderMenu: FC<MobileHeaderMenuProps> = ({isOpen, setIsOpen, onCabinetClick }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsOpen(false));
    return () => router.events.off('routeChangeStart', () => setIsOpen(false));
  }, [router]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [isOpen]);

  const headerCategoryMenuItems = useSelector(
    (state: RootState) => state.catalog.headerCategoryMenuItems,
  );

  const cartShoppingItems = useSelector((state: RootState) => state.catalog.cartItems);
  const favItems = useSelector((state: RootState) => state.catalog.favoriteItems);
  const favSaleItems = useSelector((state: RootState) => state.catalog.favoriteSaleItems);

  const favSum = favItems.length + favSaleItems.length;

  useHeaderBreadcrumbsAnalytic(headerCategoryMenuItems);

  return (
    <>
      <MobileHeaderMenuWrapper isOpen={isOpen}>
        <MobileMenuHeader>
          <CloseBtn onClick={() => setIsOpen(false)}>
            <Image
              src="/assets/mobileMenu/mobileMenuCloseBtn.svg"
              layout="intrinsic"
              width={14}
              height={14}
              objectFit="contain"
              alt="Изображение иконки закрытия мобильного меню"
              title="Закрыть мобильное меню"
            />
          </CloseBtn>
          <MobileMenuHeaderTitle>Меню</MobileMenuHeaderTitle>
          <CabIcon>
            {!ENABLE_NEW_CABINET && (
              <Link
                href="https://www.rosagroleasing.ru/cabinet/v2/client/dashboard/"
                passHref
                target="_blank"
                legacyBehavior>
                <a>
                  <Image
                    src="/assets/mobileMenu/mobileMenuCabIcon.svg"
                    width={14}
                    height={14}
                    layout="intrinsic"
                    objectFit="contain"
                    alt="Изображение иконки личного кабинета"
                    title="Личный кабинет"
                  />
                </a>
              </Link>
            )}
            {ENABLE_NEW_CABINET && (
              <Image
                src="/assets/mobileMenu/mobileMenuCabIcon.svg"
                width={14}
                height={14}
                layout="intrinsic"
                objectFit="contain"
                alt="Изображение иконки личного кабинета"
                title="Личный кабинет"
                onClick={() => onCabinetClick()}
              />
            )}
          </CabIcon>
          <GeoIcon
            onClick={e => {
              e.stopPropagation();
              dispatch(setConfirmModalActive(true));
            }}>
            <Image
              src="/assets/icons/gpsTown.svg"
              width={14}
              height={14}
              layout="intrinsic"
              objectFit="contain"
              alt="Изображение выбора города"
              title="Выбор города"
            />
          </GeoIcon>
          <CompareIcon>
            <Link href="/compare" passHref legacyBehavior>
              <a>
                <Image
                  src="/assets/mobileMenu/mobileMenuCompareIcon.svg"
                  width={14}
                  height={14}
                  layout="intrinsic"
                  objectFit="contain"
                  alt="Изображение иконки сравнения"
                  title="Сравнение товаров"
                />
              </a>
            </Link>
          </CompareIcon>
          <FavoriteIcon>
            <Link href="/favorite" passHref legacyBehavior>
              <a>
                <Image
                  src="/assets/mobileMenu/mobileMenuFavoriteIcon.svg"
                  width={14}
                  height={14}
                  layout="intrinsic"
                  objectFit="contain"
                  alt="Изображение иконки избранное"
                  title="Избранное"
                />
              </a>
            </Link>
            {Boolean(favSum) && <Quantity>{favSum}</Quantity>}
          </FavoriteIcon>
          <CartIcon>
            <Link href="/shoppingCart" passHref legacyBehavior>
              <a>
                <Image
                  src="/assets/mobileMenu/mobileMenuCartIcon.svg"
                  width={14}
                  height={14}
                  layout="intrinsic"
                  objectFit="contain"
                  alt="Изображение иконки корзины"
                  title="Корзина"
                />
              </a>
            </Link>
            {Boolean(cartShoppingItems.length) && (
              <Quantity>{cartShoppingItems.length}</Quantity>
            )}
          </CartIcon>
        </MobileMenuHeader>
        <MobileMenuSearchWrapper>
          <MobileMenuInput />
        </MobileMenuSearchWrapper>
        <MobileMenuCategoriesWrapper>
          {headerCategoryMenuItems.map(
            (item: Breadcrumb) =>
              item.title && (
                <MobileMenuAccordion
                  title={item.title}
                  content={[
                    {title: item.title, path: item.path},
                    ...(item.children ? item.children : []),
                  ]}
                  activeCategory={router.asPath.startsWith(item.path)}
                  key={item.path}
                />
              ),
          )}
        </MobileMenuCategoriesWrapper>
      </MobileHeaderMenuWrapper>

      <MobileHeaderMenuBackdrop isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
    </>
  );
};

export default MobileHeaderMenu;
